import React, { Component } from "react";
import image from "../../assets/images/me.jpg";
import file from "../../assets/files/Joshua-Allan-CV_2023.pdf";
import Workblock from "../../components/work-block";
import "./style.scss";

class Home extends Component {
  componentDidMount() {}

  render() {
    let workExp = [
      {
        company: "Leighton",
        time: "January 2017 - present",
        roles:
          "Technical lead / Senior software developer / Software developer",
        info: "In my current position at Leighton, I am the technical lead on 2 teams overseeing a shift of technologies from XSLT to Angular13. I have been involved with design discussions from useability for customers to feasibility for developers; I have worked with our business analysists on story creation and functional requirements from the designs as well as liaising with key business stakeholders on our progress and the business requirements.  Whilst at Leighton I have had many fantastic opportunities to work with and learn new frameworks such as Angular, React, Bootstrap and Foundation. I have enjoyed learning these new frameworks in this position as well as working with some big clients such as Home Group and British Airways as working with these clients as enabled me to expand my knowledge as well as my leadership and mentoring skills.",
      },
      {
        company: "FNVi",
        time: "Janurary 2015 - December 2017",
        roles: "Software developer",
        info: "For two years I worked as a frontend web developer at FNVi, at my time there I learnt a lot about best practices and improved my web development skills tenfold. I learnt a lot about design and how to make web sites and applications better and easier for the end users. While I was there, I created 16 websites, and every client was pleased with what they received.",
      },
      {
        company: "GigSource",
        time: "September 2014 - January 2015",
        roles: "Developer",
        info: "After the summer of 2014 I continued working on my own business under the Digital City Fellowship and started learning about frontend web development and how things would look from an end user perspective. By doing this I was able to make sure that the end user would have the best possible experience when using the website.",
      },
      {
        company: "Clicksco",
        time: "June 2014 - September 2014",
        roles: "Intern",
        info: "Over the summer of 2014 I worked for Clicksco as an intern. We were split into teams and had to come up with an MVP by the end of the summer. I developed a product which was a website that incorporated a MySQL database, jQuery, HTML and CSS.",
      },
    ];

    return (
      <div>
        {/* Intro */}
        <div className="intro">
          <div className="container">
            <div className="row">
              <div className="intro__image-container col s12 l4">
                <img className="circle" src={image} alt="myself at work" />
              </div>

              <div className="col s12 l8">
                <h2>
                  Hi, I'm <span className="highlight">Josh</span>
                </h2>
                <p>
                  A dynamic and resourceful professional with over 8 years of
                  experience in web development, showcasing innovation and a
                  proactive approach. Specialized in software development,
                  strategic planning, and adept in agile best practices.
                </p>
                <a
                  className="waves-effect waves-light btn cyan lighten-1"
                  href={file}
                  download
                  aria-label="Open link to PDF doc"
                >
                  Download cv <i className="fas fa-file-download"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Info */}
        <div className="info">
          <div className="container">
            <div className="row">
              {/* Personal statement */}
              <div className="col s12">
                <div className="card horizontal">
                  <div className="card-stacked">
                    <div className="card-content">
                      <span className="card-title">Personal</span>
                      <p>
                        I excel in organizational skills and decisiveness,
                        balancing the ability to collaborate effectively within
                        a team while also being highly self-motivated to
                        independently tackle challenges. I appreciate the
                        collaborative synergy of group work, leveraging
                        collective knowledge and support, yet I thrive equally
                        when immersed in solving problems on my own. My
                        enthusiasm for continuous learning and expanding my
                        expertise fuels my proactive approach to seek new
                        opportunities for personal and team growth within any
                        job role.
                      </p>
                      <p>
                        Outside of work, my primary focus is on quality time
                        with my two young sons. When I do find some free
                        moments, I enjoy playing video games with my wife.
                        Whether it's competing head-to-head or with friends in
                        Mario Kart, or tackling levels together in different
                        games, it's always an enjoyable experience.
                        Additionally, I also play Dungeons and Dragons with old
                        school friends once a month.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Qualifications */}
              <div className="col s12 m6">
                <div className="card horizontal">
                  <div className="card-stacked">
                    <div className="card-content">
                      <span className="card-title">Qualifications</span>
                      <p>Attended Teesside University from 2011 - 2014</p>
                      <ul>
                        <li>- BSc Computing - 2:1</li>
                      </ul>
                      <p>Certified ScrumMaster (CSM) passed in 2019</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Skills */}
              <div className="col s12 m6">
                <div className="card horizontal">
                  <div className="card-stacked">
                    <div className="card-content">
                      <span className="card-title">Skills</span>
                      <p><span className="skill-title">Core</span> - HTML / CSS / SCSS / JavaScript (ES5 & ES6) / jQuery</p>
                      <p><span className="skill-title">Frameworks</span> - React / Angular / AngularJS / Bootstrap / Foundation / Materialize</p>
                      <p><span className="skill-title">Tools</span> - Git / SVN</p>
                      <p><span className="skill-title">Testing</span> - Unit / Automated / Manual</p>
                      <p><span className="skill-title">Databases</span> - MongoDB / Firebase</p>
                      <p><span className="skill-title">Other</span> - Qualified SCRUM master / Accessibility principles</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Work exp */}
              <div className="col s12">
                <div className="card horizontal">
                  <div className="card-stacked">
                    <div className="card-content">
                      <span className="card-title">Work experience</span>
                      {workExp.map((data) => {
                        return (
                          <Workblock
                            company={data.company}
                            time={data.time}
                            roles={data.roles}
                            info={data.info}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
