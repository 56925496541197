import React, { Component } from "react";

// import "./style.scss";

class Workblock extends Component {
  componentDidMount() {}

  render() {
    return (
      <div>
        <span className="card-title">{this.props.company}</span>
        <p>{this.props.time} - <span className="highlight">{this.props.roles}</span></p>
        <p>
          {this.props.info}
        </p>
      </div>
    );
  }
}

export default Workblock;
